// Libraries
import React, { PureComponent } from 'react';
import BigCalendar from 'react-big-calendar';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

// Components
import ToolBar from 'marketing/components/ToolBar';

// Setup the localizer by providing the moment Object to the correct localizer.
BigCalendar.momentLocalizer(moment);

class ReactBigCalendar extends PureComponent {
  state = {
    isMobileView: window.innerWidth <= 768
  };

  isToday = event => moment().diff(event.start) > 0;

  renderEvent = ({ event }) => (
    <div>
      {event.prize && (
        <div
          className={classnames('calendar__winning-prize h6', {
            'theme-color-primary': this.isToday(event)
          })}
        >
          {event.prize.toLocaleString()}
        </div>
      )}
      {event.name && <div className="calendar__winning-name">{event.name}</div>}
      {event.ticketNumber && (
        <div className="calendar__winning-ticket">{`Ticket # ${event.ticketNumber}`}</div>
      )}
    </div>
  );

  render() {
    const { isMobileView } = this.state;
    const { winnerEvents } = this.props;
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    return (
      <div className="calendar__container">
        <BigCalendar
          components={{
            toolbar: toolbar => <ToolBar toolbar={toolbar} />,
            event: this.renderEvent
          }}
          formats={{
            agendaDateFormat: 'MMM DD, ddd'
          }}
          events={winnerEvents}
          defaultView={isMobileView ? 'agenda' : 'month'}
          views={{ month: true, agenda: true }}
          defaultDate={firstDayOfMonth}
        />
      </div>
    );
  }
}

ReactBigCalendar.propTypes = {
  winnerEvents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      start: PropTypes.date,
      end: PropTypes.date,
      prize: PropTypes.number,
      ticketNumber: PropTypes.string
    })
  ).isRequired
};

export default ReactBigCalendar;
