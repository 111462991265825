// Libraries
import React from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

class WinnersSearch extends React.PureComponent {
  state = {
    value: '',
    hasSearched: false,
    searchResult: fromJS([]),
    winners: fromJS(this.props.winners)
  };

  handleChange = event => {
    this.setState({
      value: event.target.value,
      hasSearched: false,
      searchResult: fromJS([])
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { winners, value } = this.state;

    if (value) {
      const ticketNumber = Number(value);
      const filteredWinners = winners.filter(winner => {
        if (ticketNumber) {
          return winner.get('ticketNumber') === ticketNumber;
        }
        const lastName = winner.get('lastName').toLowerCase();
        return lastName === value.toLowerCase();
      });

      this.setState({
        searchResult: filteredWinners,
        hasSearched: true
      });
    }
  };

  render() {
    const { value, searchResult, hasSearched } = this.state;

    const renderWinners = winner => (
      <tr key={winner.get('id')}>
        <td>{winner.get('amount')}</td>
        <td>{`${winner.get('firstName')} ${winner.get('lastName')}`}</td>
        <td>{winner.get('city')}</td>
        <td>{winner.get('ticketNumber')}</td>
      </tr>
    );

    const NoResultsFound = () => (
      <>
        <p className="h6">Sorry</p>
        <p>
          The information you searched for was not found in the list of winners. Thanks for your
          support!
        </p>
      </>
    );

    const ResultsTable = () => (
      <>
        <p className="h6">Congratulations!</p>
        <table className="table-list table-list--winners">
          <thead>
            <tr>
              <th>Prize</th>
              <th>Name</th>
              <th>City</th>
              <th>Ticket #</th>
            </tr>
          </thead>
          <tbody>{searchResult.map(renderWinners)}</tbody>
        </table>
      </>
    );

    const DisplayResults = () => (searchResult.isEmpty() ? <NoResultsFound /> : <ResultsTable />);

    return (
      <div className="theme-background-primary l-margin-bottom l-padding u-clearfix">
        <div className="l-col-offset-2 l-col-8 u-center-canvas">
          <p className="h4">Are you a Winner?</p>
          <form className="search" onSubmit={this.handleSubmit}>
            <input
              className="search__input"
              value={value}
              onChange={this.handleChange}
              placeholder="Enter Ticket # or Last Name"
              type="text"
              autoComplete="off"
            />
            <button
              className="search__submit theme-background-secondary theme-color-primary"
              type="submit"
            >
              Search
              <i className="material-icons">search</i>
            </button>
          </form>
        </div>

        <div className="l-col-offset-1 l-col-10 u-center-canvas">
          {hasSearched && <DisplayResults />}
          <p>All winners will be notified in writing via official winners letter.</p>
        </div>
      </div>
    );
  }
}

WinnersSearch.propTypes = {
  winners: PropTypes.arrayOf(
    PropTypes.shape({
      ticketNumber: PropTypes.number.isRequired,
      lastName: PropTypes.string.isRequired
    })
  )
};

WinnersSearch.defaultProps = {
  winners: []
};

export default WinnersSearch;
