// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';
import PrizeTile from 'marketing/components/PrizeTile';
import AddOn5050 from 'marketing/components/AddOn5050';
import Button from 'common/components/Button';
import ReactBigCalendar from 'marketing/components/ReactBigCalendar';

// Utilities
import { stripTimeAndAppendDate } from 'utils/time';

// Data
import { raffle } from 'data/data.json';

// Styles
import styles from './styles.module.css';
import WinnersSearch from '../features/marketing/components/WinnersSearch';

const generateWinnerText = ({ winnerFirstName, winnerLastName }) =>
  `${winnerFirstName} ${winnerLastName}`;

const filterSearchForPreviousWinners = (calendarWinners, timeZone) =>
  calendarWinners.filter(winner => moment(winner.date).startOf('day') < moment().tz(timeZone));

const Winners = ({ winnersPageData, pageContext, currentPrizeWinners }) => {
  const pageDataSource = pageContext ? pageContext.data : winnersPageData;
  const winnersDataSource = pageContext ? pageContext.prizeWinners : currentPrizeWinners;

  const {
    heading,
    subheading,
    introduction,
    legalText,
    thankYouMessage,
    supplierLogos,
    secondarySuppliersList,
    calendarWinners,
    winnerListUrl
  } = pageDataSource;

  const { timeZone } = raffle;

  const { grandWinners, otherWinners, addOnWinners } = winnersDataSource;

  const primaryWinnersExist = otherWinners || addOnWinners || grandWinners;
  const formattedPrimaryWinners = primaryWinnersExist
    ? [...grandWinners, ...otherWinners, ...addOnWinners].map(
        ({ winnerFirstName, winnerLastName, winnerTicketNumber }) => ({
          firstName: winnerFirstName,
          lastName: winnerLastName,
          ticketNumber: winnerTicketNumber
        })
      )
    : [];

  const structuredCalendarWinners =
    calendarWinners &&
    calendarWinners.map((item, index) => {
      const itemDate = stripTimeAndAppendDate(item.time || new Date(), item.date, timeZone);
      const currentTime = moment().tz(timeZone);
      const isSameDay = itemDate.isSame(currentTime, 'day');

      if ((itemDate <= currentTime && isSameDay) || (!isSameDay && itemDate < currentTime)) {
        return {
          id: index,
          prize: item.amount,
          name: `${item.firstName} ${item.lastName}`,
          ticketNumber: item.ticketNumber,
          start: itemDate,
          end: itemDate
        };
      }
      return {};
    });

  const renderView = primaryWinnersExist || calendarWinners;
  const showTitleBlock = heading || subheading || introduction;

  return (
    <LayoutContainer disableGlobalBanner>
      {showTitleBlock && (
        <div className="l-col-offset-2 l-col-8">
          <TitleBlock mainHeading={heading} subHeading={subheading} />
          <div className="wysiwyg l-margin-top">
            <div
              className="t-intro t-center theme-color-primary l-margin"
              dangerouslySetInnerHTML={{ __html: introduction }}
            />
          </div>
        </div>
      )}

      <Tabs selectedTabClassName="theme-background-primary react-tabs__tab--selected">
        <TabList className="react-tabs__tab-list theme-background-secondary l-margin">
          {renderView && <Tab>Home Lottery Winners</Tab>}

          {structuredCalendarWinners && <Tab>Cash Calendar Winners</Tab>}
        </TabList>

        {renderView && (
          <TabPanel>
            {calendarWinners && (
              <WinnersSearch
                winners={[
                  ...filterSearchForPreviousWinners(calendarWinners, timeZone),
                  ...formattedPrimaryWinners
                ]}
              />
            )}
            {grandWinners && grandWinners.length > 0 && (
              <div className="theme-background-primary l-padding">
                <div className={styles.layoutContainer}>
                  {grandWinners.map(prizeWinner => (
                    <PrizeTile
                      mainHeading={prizeWinner.heroHeading}
                      subHeading="Congratulations"
                      winnerText={generateWinnerText(prizeWinner)}
                      backgroundUrl={prizeWinner.heroImage}
                      key={prizeWinner.winnerTicketNumber}
                    />
                  ))}
                </div>
              </div>
            )}

            {addOnWinners && addOnWinners.length > 0 && (
              <div className={`${styles.layoutContainer} l-padding-top`}>
                {addOnWinners.map(addOnWinner => (
                  <AddOn5050
                    mainHeading="And the Final Jackpot..."
                    subHeading={generateWinnerText(addOnWinner)}
                    imageSrc={addOnWinner.pageLogo}
                    backgroundImageUrl={addOnWinner.pageBackgroundImage}
                    total={addOnWinner.currentPrizeTotal}
                    key={addOnWinner.winnerTicketNumber}
                  />
                ))}
              </div>
            )}

            {otherWinners && otherWinners.length > 0 && (
              <div className={styles.layoutContainer}>
                {otherWinners.map(prizeWinner => (
                  <PrizeTile
                    mainHeading={prizeWinner.heroHeading}
                    subHeading="Congratulations"
                    winnerText={generateWinnerText(prizeWinner)}
                    backgroundUrl={prizeWinner.heroImage}
                    key={prizeWinner.winnerTicketNumber}
                  />
                ))}
              </div>
            )}

            <div className={styles.layoutContainer}>
              <div className="l-col-offset-2 l-col-8">
                <hr className="theme-background-primary l-margin" />
              </div>

              {thankYouMessage && (
                <div className="l-col-12 wysiwyg l-margin-bottom">
                  <p className="theme-color-primary t-intro u-text-align-center">
                    {thankYouMessage}
                  </p>
                </div>
              )}

              {supplierLogos && supplierLogos.length > 0 && (
                <div className="l-col-12 u-flex-center">
                  {supplierLogos.map(supplierLogo => (
                    <div className="l-col-4 u-text-align-center l-margin-bottom">
                      <img
                        className="u-block u-margin-center"
                        src={supplierLogo.image}
                        alt="Supplier Logo"
                      />
                    </div>
                  ))}
                </div>
              )}

              {secondarySuppliersList && (
                <div className="l-col-offset-3 l-col-6 wysiwyg u-text-align-center">
                  <div
                    className="theme-color-primary p"
                    dangerouslySetInnerHTML={{ __html: secondarySuppliersList }}
                  />
                </div>
              )}
            </div>
          </TabPanel>
        )}

        {structuredCalendarWinners && (
          <TabPanel>
            <div className="theme-background-primary l-padding">
              <div className={styles.layoutContainer}>
                <ReactBigCalendar winnerEvents={structuredCalendarWinners} />
              </div>
            </div>
          </TabPanel>
        )}
      </Tabs>

      {winnerListUrl && (
        <div className={`${styles.layoutContainer} u-text-align-center`}>
          <Button
            to={winnerListUrl}
            text="View Full Winners List"
            modifiers="secondary large"
            extraClasses={styles.winnerListButton}
            external
          />
        </div>
      )}

      {legalText && (
        <div className="l-col-offset-2 l-col-8 wysiwyg l-margin">
          <hr className="theme-background-primary l-margin-bottom" />
          <div className="p t-small" dangerouslySetInnerHTML={{ __html: legalText }} />
        </div>
      )}
    </LayoutContainer>
  );
};

Winners.propTypes = {
  winnersPageData: PropTypes.shape({}),
  pageContext: PropTypes.shape({}),
  currentPrizeWinners: PropTypes.shape({})
};

Winners.defaultProps = {
  winnersPageData: {},
  pageContext: null,
  currentPrizeWinners: null
};

export default Winners;
