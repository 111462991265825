// Libraries
import React, { Fragment } from 'react';
import moment from 'moment-timezone';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import GrandPrizeSwiper from 'marketing/components/GrandPrizeSwiper';
import GrandPrizeGrid from 'marketing/components/GrandPrizeGrid';
import CategoryPrizeSwiper from 'marketing/components/CategoryPrizeSwiper';
import CtaBlock from 'marketing/components/CtaBlock';
import PrizeTile from 'marketing/components/PrizeTile';
import TitleBlock from 'marketing/components/TitleBlock';
import AddOn5050 from 'marketing/components/AddOn5050';
import AddOn100Days from 'marketing/components/AddOn100Days';
import NotificationContainer from 'marketing/containers/NotificationContainer';
import Navigation from 'marketing/components/Navigation';

// Utilities
import { getFeaturedCategoryPrizes } from 'utils/helpers';
import { getCurrentPrizeWinners } from 'utils/prizes';
import { stripTimeAndAppendDate } from 'utils/time';
import { prizeTypes } from 'utils/constants';

// Data
import data from 'data/data.json';

// Templates (for winners/maintenance mode)
import Maintenance from '../templates/maintenance';
import Winners from '../templates/winners';

const checkForEarlyBirdTimer = (primaryPrize, timeZone) =>
  moment()
    .tz(timeZone)
    .isBefore(
      stripTimeAndAppendDate(
        primaryPrize.removeFromHomepageTime,
        primaryPrize.removeFromHomepageDate,
        timeZone
      )
    );

const indexPageComponment = () => {
  const {
    maintenance,
    primaryPrizePages,
    notificationBanner,
    homePage: {
      homePageSlides,
      grandPrizeCtaHeading,
      grandPrizeCtaSubheading,
      grandPrizeCtaButtonText,
      grandPrizeHeading,
      grandPrizeSubheading,
      carouselHeading,
      carouselSubheading
    } = {},
    globalModule: { orderTicketsHeading, subheading, ticketPriceText, backgroundImage } = {},
    categoryPrizePages,
    addOnPages,
    appearance: { programLogo } = {},
    winnersPage,
    timeZone
  } = data.raffle;

  // If maintenace mode is enabled then only render the Maintenance template.
  if (maintenance && maintenance.maintenanceMode) {
    const { heading, messageText } = maintenance;

    return (
      <Maintenance
        heading={heading}
        subheading={maintenance.subheading}
        messageText={messageText}
        logoSrc={programLogo}
      />
    );
  }

  const currentPrizeWinners = getCurrentPrizeWinners(primaryPrizePages, addOnPages, winnersPage);

  // If Winners mode is enabled then only render the Winners template.
  if (winnersPage && winnersPage.winnersMode) {
    return <Winners winnersPageData={winnersPage} currentPrizeWinners={currentPrizeWinners} />;
  }

  const displayGlobalBanner = notificationBanner && notificationBanner.displayBanner;

  // Featured Primary Prizes are primary prizes that have the displayHeroOnHomepage flag and are NOT Grand Prizes (e.g Early Bird & Bonus prize(s))
  const featuredPrimaryPrizes = primaryPrizePages
    .filter(
      primaryPrize =>
        primaryPrize.prizeType !== prizeTypes.GRAND_PRIZE && primaryPrize.displayHeroOnHomepage
    )
    .filter(primaryPrize =>
      primaryPrize.prizeType === prizeTypes.EARLY_BIRD &&
      primaryPrize.removeFromHomepageDate &&
      primaryPrize.removeFromHomepageTime
        ? checkForEarlyBirdTimer(primaryPrize, timeZone)
        : true
    )
    .sort((a, b) => {
      if (a.displayOrderIndex && b.displayOrderIndex) {
        return a.displayOrderIndex - b.displayOrderIndex;
      }
      if (!a.displayOrderIndex) {
        return 1;
      }
      return -1;
    });

  // Other Grand Prizes are all grand prizes except for the first (which is the Grandest of Grand prizes)
  const otherGrandPrizes = primaryPrizePages
    .filter(prize => prize.prizeType === prizeTypes.GRAND_PRIZE)
    .slice(1);

  // Featured Catgeory Prizes are up to the first three prizes from each category prize page.
  const featuredCategoryPrizes = getFeaturedCategoryPrizes(categoryPrizePages);

  // Featured Add On Prizes (cash calendar or 50/50)
  const featuredAddOns = addOnPages.filter(addOnPage => addOnPage.included);

  const homePageNavigation = (
    <Navigation hasNotification={displayGlobalBanner} isHomePage>
      <NotificationContainer global />
      <GrandPrizeSwiper slides={homePageSlides} programLogo={programLogo} />
    </Navigation>
  );

  const renderAddOn = (addOn, i) => {
    switch (addOn.addOnType) {
      case 'fifty_fifty':
        return (
          <AddOn5050
            mainHeading={addOn.titleSubheading}
            pageBannerText={addOn.pageBannerText}
            imageSrc={addOn.pageLogo}
            pageBackgroundImage={addOn.pageBackgroundImage}
            total={addOn.currentPrizeTotal}
            timers={addOn.timers}
            timeZone={timeZone}
            key={i}
          />
        );
      case 'cash_calendar':
        return (
          <AddOn100Days
            mainHeading={addOn.titleSubheading}
            pageBannerText={addOn.pageBannerText}
            imageSrc={addOn.pageLogo}
            pageBackgroundImage={addOn.pageBackgroundImage}
            key={i}
          />
        );
      default:
        return false;
    }
  };

  return (
    <LayoutContainer homeHeroNav={homePageNavigation} includeWinnersLink={!!currentPrizeWinners}>
      <div className="u-relative u-bg-canvas u-z-index-10">
        <CtaBlock
          mainHeading={grandPrizeCtaHeading}
          subHeading={grandPrizeCtaSubheading}
          buttonText={grandPrizeCtaButtonText}
          buttonTo="/grand-prize"
        />

        <div className="l-container l-margin-top">
          {featuredPrimaryPrizes.map(featuredPrimaryPrize => (
            <Fragment key={featuredPrimaryPrize.pageName}>
              {featuredPrimaryPrize.displayBanner && (
                <NotificationContainer
                  message={featuredPrimaryPrize.bannerMessage}
                  linkText={featuredPrimaryPrize.bannerTicketsLinkText}
                />
              )}

              <PrizeTile
                mainHeading={featuredPrimaryPrize.heroHeading}
                subHeading={featuredPrimaryPrize.heroSubheading}
                buttonText="More Info"
                buttonTo={featuredPrimaryPrize.pageName.toLowerCase().replace(/\s/g, '-')}
                backgroundUrl={featuredPrimaryPrize.heroImage}
              />
            </Fragment>
          ))}
        </div>

        {otherGrandPrizes.length > 0 && (
          <GrandPrizeGrid
            twoUp={false}
            mainHeading={grandPrizeHeading}
            subHeading={grandPrizeSubheading}
            otherGrandPrizes={otherGrandPrizes}
          />
        )}

        {featuredCategoryPrizes.length > 0 && (
          <>
            <TitleBlock mainHeading={carouselHeading} subHeading={carouselSubheading} />
            <CategoryPrizeSwiper prizes={featuredCategoryPrizes} />
          </>
        )}

        {featuredAddOns.length > 0 && (
          <div className="l-container">{featuredAddOns.map(renderAddOn)}</div>
        )}

        <CtaBlock
          mainHeading={orderTicketsHeading}
          subHeading={subheading}
          pricing={ticketPriceText}
          backgroundImage={backgroundImage}
        />
      </div>
    </LayoutContainer>
  );
};

export default indexPageComponment;
